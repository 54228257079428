import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { LazyBlocksFactory } from '../components/LazyBlocksFactory/LazyBlocksFactory'
import MainGrid from '../components/styling/MainGrid'
import { CustomerStoriesCard } from '../components/CustomerStories/CustomerStoriesCard'
import styled from 'styled-components'
interface Props {
  data: any
  pageContext: any
}

const CustomerStories = ({ data, pageContext }: Props) => {
  const { wpPage, allWpTestimonial } = data

  // const lazyBlocks = parse(wpPage.lazy_data)
  const renderedBlocks = LazyBlocksFactory(wpPage.lazy_data, wpPage.title)
  return (
    <Layout>
      {renderedBlocks.map((v) => v)}

      <MainGrid>
        <CardContainer>
          {allWpTestimonial.nodes.map((v, i) => {
            return (
              <CustomerStoriesCard
                key={'customer-story-card-' + i}
                customerStory={v}
              />
            )
          })}
        </CardContainer>
      </MainGrid>
    </Layout>
  )
}

const CardContainer = styled.div`
  cursor: pointer;
  width: 100%;
  margin: 0 auto;
  padding-top: 28px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 30px;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(3, 1fr);
    padding-top: 50px;
    grid-row-gap: 30px;
    grid-column-gap: 30px;
  }
`

export default CustomerStories

export const pageQuery = graphql`
  query customerStories {
    wpPage(title: { eq: "Customer Stories" }) {
      id
      lazy_data
      title
    }
    allWpTestimonial {
      nodes {
        title
        slug
        acfTestimonial {
          description
          logo {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1080, toFormat: WEBP) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
