import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Link } from 'gatsby'
import theme from '../styling/theme'
interface CustomerStoriesCardInterface {
  customerStory: any
}

export const CustomerStoriesCard: React.FC<CustomerStoriesCardInterface> = ({
  customerStory,
}) => {
  const featureimage =
    customerStory.acfTestimonial.logo.localFile.childImageSharp.fluid

  // var string = "this is a string";
  const length = 130
  const trimmedDescription =
    customerStory.acfTestimonial.description.length > length
      ? customerStory.acfTestimonial.description.substring(0, length - 3) +
        '...'
      : customerStory.acfTestimonial.description
  return (
    <Card to={'/customer-stories/' + customerStory.slug}>
      <LogoContainer>
        <Img
          fluid={featureimage}
          style={{ width: 'auto', maxHeight: 50 }}
          imgStyle={{ width: 'auto', maxHeight: 50 }}
        />
      </LogoContainer>
      <TitleContainer>{customerStory.title}</TitleContainer>
      <DescriptionContainer>{trimmedDescription}</DescriptionContainer>
    </Card>
  )
}

const Card = styled(Link)`
  transition: all 0.2s;
  width: 100%;
  position: relative;
  background-color: #fafafa;
  border-top: 3px solid ${theme.brand.blue};
  border-left: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
  border-right: 1px solid #fafafa;
  text-decoration: none;
  font-family: Montserrat;
  min-height: 340px;
  &:hover {
    -webkit-box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.25);
    /* border-left: 1px solid ${(props) => props.color};
    border-bottom: 1px solid ${(props) => props.color};
    border-right: 1px solid ${(props) => props.color}; */
    text-decoration: none;
  }
  &:hover > #HoverBar {
    width: 100%;
  }
  @media screen and (min-width: 1024px) {
    max-width: 46rem;
    padding-bottom: 40px;
  }

  &::after {
    content: '';
    width: 0px;
    height: 5px;
    background-color: ${(props) => props.color};
    position: absolute;
    left: 0px;
    top: -2px;
    right: 0;
    transition: all 0.3s ease;
  }

  &:hover::after {
    width: calc(100% + 1px);
  }
`

const LogoContainer = styled.div`
  /* max-height: 50px; */
  overflow: hidden;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 40px;
`
const TitleContainer = styled.h5`
  /* max-height: 50px; */
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  color: black;
  font-weight: 700;
`

const DescriptionContainer = styled.p`
  /* max-height: 50px; */
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
`
